<template>
  <modal
    name="confirmDetails"
    class="confirm-modal"
    :scrollable="true"
    :height="'auto'"
    @closed="hideWelcomePage"
    :clickToClose="false"
  >
    <div class="">
      <div class="w-100">
        <div class="row p-0 mt-5 mx-0">
          <div class="w-100 p-0 m-0">
            <div class="w-100">
              <div class="w-100 content-center">
                <i
                  class="fas fa-exclamation-triangle"
                  style="color: rgb(255, 90, 90); font-size: 50px"
                ></i>
              </div>
              <div class="w-100 my-3 content-center">
                <span class="font-large ProximaNovaBold">Confirmation</span>
              </div>
            </div>
            <div
              class="w-100 content-center d-grid pt-5 mt-2"
              style="border-top: 1px solid; background: #efef"
            >
              <span class="font-medium ProximaNovaSemiBold">
                <i
                  class="fa fa-exclamation-circle"
                  aria-hidden="true"
                  style="color: rgb(255, 90, 90);"
                ></i>
                {{modalTitle}}.</span
              >
              <span class="font-medium ProximaNovaSemiBold mt-3" style="text-align: center;">
                Do you still want to Continue?</span
              >
            </div>
            <div
              class="d-flex w-100 py-5"
              style="justify-content: space-around; background: #efef;"
            >
              <w-button
                :buttonText="'No,Keep here'"
                :buttonLoader="'none'"
                class="cancel"
                @buttonClicked="hideWelcomePage"
              ></w-button>
              <w-button
                :buttonText="'Yes,Continue'"
                :buttonLoader="'none'"
                :isDisable="isDisable"
                :class="{ resetPasswordLink: isDisable }"
                @buttonClicked="deleteUser"
              ></w-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Button from "@/components/Profile/Button.vue";
export default {
  name: "ConfirmationModal",
  components: {
    "w-button": Button,
  },
  data() {
    return {
      modalTitle: "",
      userName: "",
      userId: "",
    };
  },
  computed: {
    isDisable() {
      if (this.userId !== sessionStorage.getItem("userId")) {
        return false;
      }
      return true;
    },
  },
  methods: {
    open(data) {
      this.buttonLoaderAddConnection = "normal";
      this.modalTitle = data.title;
      this.$modal.show("confirmDetails");
    },
    hideWelcomePage() {
      this.$modal.hide("confirmDetails");
    },
    deleteUser() {
      this.hideWelcomePage()
      this.$emit("continue");
    },
  },
};
</script>
<style>
.confirm-modal .vm--modal {
  width: 30% !important;
  box-shadow: rgb(201 206 213) 0px 0px 8px 0px;
  margin: auto;
  display: block;
  top: 0 !important;
  left: auto !important;
}
.form-control > input:focus {
  background: #eff5ff !important;
}
</style>
<style lang="css" scoped>
span.start-from-fresh.mt-4 {
  width: 100%;
  height: 22px;
  font-size: 18px;
  font-weight: 600;
  float: left;
  cursor: pointer;
}
.cancel.button.update {
  background-color: transparent;
  color: #050505;
}
.cancel.button.update:hover {
  color: #fff !important;
}
#exampleInputEmail2:hover {
  color: #050505;
}
#exampleInputEmail1,
#exampleInputEmail2 {
  box-shadow: none;
  background-color: #eff5ff;
  border: 0px;
  font-weight: 500;
}
#exampleInputEmail2 {
  width: 378px;
  height: 60px;
  margin: 53px 1px 40px 30px;
  object-fit: contain;
  color: #9aafd4;
  font-family: ProximaNovaRegular;
  font-size: 16px;

  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
@media screen and (max-width: 900px) {
  p.Welcome-to-Data-Poem.ProximaNovaRegular {
    text-align: center;
  }
  p.Together-let-us-hel.ProximaNovaRegular.mt-0.w-75 {
    margin-left: 50px;
  }
  button.btn-effect-intro {
    margin-left: 50px;
  }
}
</style>
